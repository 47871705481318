import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from '../fragments/global';
import { ABSTRACT_PROFILE_FRAGMENT, PROFILE_PAID_FEATURES_FRAGMENT } from '../fragments/profile';

const EXTENDED_ABSTRACT_PROFILE_FRAGMENT = gql`
  fragment ExtendedAbstractProfileFragment on AbstractProfile {
    ...AbstractProfileFragment
    b2bReportAccessHash
    highestActiveContract {
      type
    }
    paidFeatures {
      ...ProfilePaidFeaturesFragment
    }
  }
  ${ABSTRACT_PROFILE_FRAGMENT}
  ${PROFILE_PAID_FEATURES_FRAGMENT}
`;

export const DEFAULT_PROFILE_FOR_CURRENT_USER_IN_B2B_ADMIN = gql`
  query DefaultProfileForCurrentUserInB2bAdmin {
    defaultProfileForCurrentUserInB2bAdmin {
      ...ExtendedAbstractProfileFragment
    }
  }
  ${EXTENDED_ABSTRACT_PROFILE_FRAGMENT}
`;

export const PROFILE_BY_B2B_REPORT_ACCESS_HASH = gql`
  query ProfileByB2bReportAccessHash($b2bReportAccessHash: String!) {
    profileByB2bReportAccessHash(b2bReportAccessHash: $b2bReportAccessHash) {
      ...ExtendedAbstractProfileFragment
    }
  }
  ${EXTENDED_ABSTRACT_PROFILE_FRAGMENT}
`;

export const CAN_CURRENT_USER_ACCESS_B2B_ADMIN = gql`
  query CanCurrentUserAccessB2bAdmin($b2bReportAccessHash: String!) {
    canCurrentUserAccessB2bAdmin(b2bReportAccessHash: $b2bReportAccessHash) {
      email
      isAuthorized
      companyName
    }
  }
`;

export const AVAILABLE_PROFILES_FOR_CURRENT_USER_AS_CONTACT_PERSON = gql`
  query AvailableProfilesForCurrentUserAsContactPerson {
    availableProfilesForCurrentUserAsContactPerson {
      ...ExtendedAbstractProfileFragment
    }
  }
  ${EXTENDED_ABSTRACT_PROFILE_FRAGMENT}
`;

export const PROFILE_VISUAL_CONTENT_ITEMS = gql`
  query ProfileVisualContentItems($b2bReportAccessHash: String!, $profileVisualContentType: String!) {
    profileVisualContentItems(
      b2bReportAccessHash: $b2bReportAccessHash
      profileVisualContentType: $profileVisualContentType
    ) {
      position
      profileVisualContentItemData {
        title
        description
        image {
          ...ImageFragment
        }
        linkToYoutubeVideo
        type
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const COVER_PHOTO = gql`
  query CoverPhoto($b2bReportAccessHash: String!) {
    coverPhoto(b2bReportAccessHash: $b2bReportAccessHash) {
      type
      image {
        ...ImageFragment
      }
      title
      description
      suitableFor {
        questionnaireLabel
      }
      description
      position
      linkToYoutubeVideo
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const AVAILABLE_MONTHLY_REPORTS = gql`
  query AvailableMonthlyReports($b2bReportAccessHash: String!) {
    availableMonthlyReports(b2bReportAccessHash: $b2bReportAccessHash) {
      label
      value
      isCumulative
    }
  }
`;

export const PROFILE_B2B_REPORT_DATA = gql`
  query ProfileB2bReportData($b2bReportAccessHash: String!, $month: String!) {
    profileB2bReportData(b2bReportAccessHash: $b2bReportAccessHash, month: $month) {
      hasMediumMetrics
      hasPremiumMetrics
      countOfTotalProfilePageviews
      premiumProfilePageviewIncreaseRate
      countOfProfilePageviewsWithoutPaidFeatures
      profilePageviewRank
      profilePageviewRankNoPremium
      countOfCompanyBrandViews
      countOfJobDescriptionViews
      countOfJobDescriptionApplications
      countOfFollowers
      timeOnProfilePageInSeconds
      countOfAddedQuestionnaires
      averageReviewValueInPercent
      payHappinessAverageReviewValueInPercent
      recommendationAverageReviewValueInPercent
    }
  }
`;
