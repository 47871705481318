import { useQuery } from '@apollo/client';
import { Container } from '@lmc-eu/spirit-web-react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useB2bProfile } from '@components/B2bAdmin/hooks/useB2bProfile';
import B2bAdminHamburgerMenuToggle from '@components/HamburgerMenu/B2bAdminHamburgerMenuToggle';
import HamburgerMenu, {
  MenuItemSelectType,
  MenuItemTypes,
  MenuItemUnionType,
} from '@components/HamburgerMenu/HamburgerMenu';
import Gradient from '@components/utilities/Gradient/Gradient';
import Logo from '@components/utilities/Logo/Logo';
import NextButtonLink from '@components/utilities/NextButtonLink/NextButtonLink';
import CustomControlB2bAdmin from '@components/utilities/NextSelect/components/CustomControl/CustomControlB2bAdmin';
import NextSelect from '@components/utilities/NextSelect/NextSelect';
import { SelectOptionType } from '@components/utilities/Select/Select';
import { useActiveBreakpoint } from '@hooks/useActiveBreakpoint';
import useActiveLink from '@hooks/useActiveLink';
import { useUser } from '@hooks/useUser';
import pushGAInteractionEvent from '@libs/ga/pushGAInteractionEvent';
import { getProfileDetailUrl } from '@libs/getProfileDetailUrl';
import { useGenerateRoute, useGetLocalizedRoute } from '@libs/localizedRoute';
import { Route } from '@libs/routes';
import { getUrlWithParameters } from '@libs/urlUtils';
import BarGraphSvg from 'public/icons/bar-graph.svg';
import PenSvg from 'public/icons/pen-fill.svg';
import TabletSvg from 'public/icons/tablet.svg';
import classes from './B2bAdminNavbar.module.scss';
import { ProfileContract, ProfileVisualContentType } from '../../../generated/phpConstants';
import {
  AbstractProfile,
  AvailableProfilesForCurrentUserAsContactPersonQuery,
  AvailableProfilesForCurrentUserAsContactPersonQueryVariables,
} from '../../../graphql/generated/types/graphqlTypes';
import { AVAILABLE_PROFILES_FOR_CURRENT_USER_AS_CONTACT_PERSON } from '../../../graphql/queries/b2bAdmin';

const B2bAdminNavbar = () => {
  const { t, i18n } = useTranslation();
  const getLocalizedRoute = useGetLocalizedRoute();
  const generateRoute = useGenerateRoute();
  const { isDesktop } = useActiveBreakpoint();
  const user = useUser();
  const { query, push } = useRouter();
  const isActive = useActiveLink();
  const b2bReportAccessHashFromUrl = query.reportToken?.[0] ?? '';
  const selectedCompany = useB2bProfile(b2bReportAccessHashFromUrl);

  const { data } = useQuery<
    AvailableProfilesForCurrentUserAsContactPersonQuery,
    AvailableProfilesForCurrentUserAsContactPersonQueryVariables
  >(AVAILABLE_PROFILES_FOR_CURRENT_USER_AS_CONTACT_PERSON);

  if (!selectedCompany) {
    return null;
  }

  const mapAbstractProfileToAvailableProfilesSelectOptionType = (profile: AbstractProfile): SelectOptionType => {
    return {
      value: getUrlWithParameters(`${getLocalizedRoute(Route.b2bAdminMonthlyReport)}/${profile.b2bReportAccessHash}`, {
        contract: profile.highestActiveContract?.type
          ? ProfileContract[profile.highestActiveContract?.type]
          : ProfileContract.TYPE_NO_CONTRACT,
      }),
      label: profile.expandedName,
    };
  };

  const companyOptionsData: SelectOptionType[] = data?.availableProfilesForCurrentUserAsContactPerson
    ? data.availableProfilesForCurrentUserAsContactPerson.map((item) =>
        mapAbstractProfileToAvailableProfilesSelectOptionType(item),
      )
    : [];

  const defaultReportForUserAccessHash =
    data?.availableProfilesForCurrentUserAsContactPerson?.[0].b2bReportAccessHash ?? b2bReportAccessHashFromUrl;

  const onCompanySelectChange = (option: SelectOptionType) => {
    push(option.value);
  };

  const companySelectData: MenuItemSelectType = {
    type: MenuItemTypes.Select,
    id: 'available-profiles',
    name: 'available_profiles',
    options: companyOptionsData,
    onChange: onCompanySelectChange,
    value: selectedCompany ? mapAbstractProfileToAvailableProfilesSelectOptionType(selectedCompany) : null,
  };

  const hamburgerMenuItems: MenuItemUnionType[] = [];

  if (!isDesktop) {
    if (companyOptionsData.length > 1) {
      hamburgerMenuItems.push(companySelectData);
    } else if (companyOptionsData[0]) {
      hamburgerMenuItems.push({
        type: MenuItemTypes.Link,
        title: companyOptionsData[0].label,
        url: '',
        disabled: true,
      });
    }
    hamburgerMenuItems.push({
      type: MenuItemTypes.Line,
    });
  }
  hamburgerMenuItems.push({
    type: MenuItemTypes.Link,
    title: user!.email,
    url: '',
    disabled: true,
  });
  hamburgerMenuItems.push({
    type: MenuItemTypes.Line,
  });
  hamburgerMenuItems.push({
    type: MenuItemTypes.Link,
    title: t('COMPANY_ADMINISTRATION'),
    url: `${getLocalizedRoute(Route.b2bAdminMonthlyReport)}/${defaultReportForUserAccessHash}`,
  });
  if (!isDesktop) {
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('SECTION_REPORTS', { ns: 'b2bVisualContent' }),
      url: `${getLocalizedRoute(Route.b2bAdminMonthlyReport)}/${b2bReportAccessHashFromUrl}`,
      isInGroup: true,
    });
    if (selectedCompany.paidFeatures?.hasAboutUs) {
      hamburgerMenuItems.push({
        type: MenuItemTypes.Link,
        title: t('SECTION_ABOUT_US', { ns: 'b2bAboutUs' }),
        url: `${getLocalizedRoute(Route.b2bAdminAboutUs)}/${b2bReportAccessHashFromUrl}`,
        isInGroup: true,
      });
    }
    if (selectedCompany.paidFeatures?.hasPhoto || selectedCompany.paidFeatures?.hasProfileVisualContentGallery) {
      hamburgerMenuItems.push({
        type: MenuItemTypes.Link,
        title: t('SECTION_VISUAL_CONTENT_ADMINISTRATION', { ns: 'b2bVisualContent' }),
        url: `${getLocalizedRoute(Route.b2bAdminVisualContent)}/${b2bReportAccessHashFromUrl}`,
        isInGroup: true,
      });
    }
  }
  hamburgerMenuItems.push({
    type: MenuItemTypes.Link,
    title: t('INFORMATION_FOR_COMPANIES', { ns: 'b2bVisualContent' }),
    url: getLocalizedRoute(Route.informationForCompanies),
  });
  if (!isDesktop) {
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('SHOW_PROFILE_ON_ATMOSKOP', { ns: 'b2bVisualContent' }),
      url: getProfileDetailUrl(i18n.language, selectedCompany),
      target: '_blank',
    });
  }
  hamburgerMenuItems.push({
    type: MenuItemTypes.Line,
  });
  hamburgerMenuItems.push({
    type: MenuItemTypes.Link,
    title: t('RECOMMENDED_COMPANIES'),
    url: getLocalizedRoute(Route.profileMatchingResult),
  });
  hamburgerMenuItems.push({
    type: MenuItemTypes.Link,
    title: t('MY_SUBSCRIBED_COMPANIES'),
    url: generateRoute('front.userAccount.manageSubscriptions'),
  });
  if (isDesktop) {
    hamburgerMenuItems.push({
      type: MenuItemTypes.Line,
    });
  }
  hamburgerMenuItems.push({
    type: MenuItemTypes.Link,
    title: t('LOGOUT'),
    url: generateRoute('front.logout'),
    isLinkPrimary: true,
    onClick: () => {
      pushGAInteractionEvent('user.logout', 'user', 'user.logout', 'window.atmoskop.userId');
    },
    dataCyItem: 'logout-button',
  });

  const isAnyOfB2bAdminVisualContentActive =
    isActive(`${getLocalizedRoute(Route.b2bAdminVisualContent)}/${b2bReportAccessHashFromUrl}`) ||
    isActive(
      `${getLocalizedRoute(Route.b2bAdminVisualContent)}/${b2bReportAccessHashFromUrl}/${
        ProfileVisualContentType.SUMMARY
      }`,
    ) ||
    isActive(
      `${getLocalizedRoute(Route.b2bAdminVisualContent)}/${b2bReportAccessHashFromUrl}/${
        ProfileVisualContentType.COMMENTS
      }`,
    ) ||
    isActive(
      `${getLocalizedRoute(Route.b2bAdminVisualContent)}/${b2bReportAccessHashFromUrl}/${
        ProfileVisualContentType.CULTURE
      }`,
    );

  return (
    <div className={classes.B2bAdminNavbar}>
      <Gradient reverse={false} />
      <Container>
        <div className={classes.B2bAdminNavbar__contentWrapper}>
          <div className={classes.B2bAdminNavbar__leftContent}>
            <Link href={generateRoute('homepage')}>
              <Logo />
            </Link>
            {isDesktop && (
              <div className={classes.B2bAdminNavbar__leftContentText}>
                <NextButtonLink
                  href={`${getLocalizedRoute(Route.b2bAdminMonthlyReport)}/${b2bReportAccessHashFromUrl}`}
                  color="secondary"
                  className={classNames(
                    classes.B2bAdminNavbar__menuItem,
                    isActive(`${getLocalizedRoute(Route.b2bAdminMonthlyReport)}/${b2bReportAccessHashFromUrl}`)
                      ? classes.B2bAdminNavbar__menuItemActive
                      : '',
                  )}
                >
                  <BarGraphSvg />
                  {t('SECTION_REPORTS', { ns: 'b2bVisualContent' })}
                </NextButtonLink>
                {selectedCompany.paidFeatures?.hasAboutUs && (
                  <NextButtonLink
                    href={`${getLocalizedRoute(Route.b2bAdminAboutUs)}/${b2bReportAccessHashFromUrl}`}
                    color="secondary"
                    className={classNames(
                      classes.B2bAdminNavbar__menuItem,
                      isActive(`${getLocalizedRoute(Route.b2bAdminAboutUs)}/${b2bReportAccessHashFromUrl}`)
                        ? classes.B2bAdminNavbar__menuItemActive
                        : '',
                    )}
                  >
                    <PenSvg />
                    {t('SECTION_ABOUT_US', { ns: 'b2bAboutUs' })}
                  </NextButtonLink>
                )}
                {(selectedCompany.paidFeatures?.hasPhoto ||
                  selectedCompany.paidFeatures?.hasProfileVisualContentGallery) && (
                  <NextButtonLink
                    href={`${getLocalizedRoute(Route.b2bAdminVisualContent)}/${b2bReportAccessHashFromUrl}`}
                    color="secondary"
                    className={classNames(
                      classes.B2bAdminNavbar__menuItem,
                      isAnyOfB2bAdminVisualContentActive ? classes.B2bAdminNavbar__menuItemActive : '',
                    )}
                  >
                    <TabletSvg />
                    {t('SECTION_VISUAL_CONTENT_ADMINISTRATION', { ns: 'b2bVisualContent' })}
                  </NextButtonLink>
                )}
              </div>
            )}
          </div>
          <div className={classes.B2bAdminNavbar__rightContent}>
            <div className={classes.B2bAdminNavbar__companySelectWrapper}>
              {selectedCompany.paidFeatures?.hasLogo && selectedCompany.logo && (
                <div className={classes.B2bAdminNavbar__profileLogo}>
                  <img src={selectedCompany.logo.imageUrl} alt={selectedCompany.expandedName} />
                </div>
              )}
              <div>
                {companyOptionsData.length > 1 ? (
                  <NextSelect
                    className={classes.B2bAdminNavbar__companySelect}
                    id={companySelectData.id}
                    onChange={companySelectData.onChange}
                    customComponents={{ Control: CustomControlB2bAdmin }}
                    options={companySelectData.options}
                    name={companySelectData.name}
                    isClearable={false}
                    value={companySelectData.value}
                    blurInputOnSelect
                    placeholder={''}
                  />
                ) : (
                  companyOptionsData[0]?.label
                )}
              </div>
            </div>
            <div className="d-desktop-flex align-items-center">
              <HamburgerMenu menuItems={hamburgerMenuItems} icon={<B2bAdminHamburgerMenuToggle />} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default B2bAdminNavbar;
