import { Button, Text } from '@lmc-eu/spirit-web-react';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useActiveBreakpoint } from '@hooks/useActiveBreakpoint';
import UserSvg from 'public/icons/user.svg';

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  onClick?: () => void;
  isMenuOpen?: boolean;
}

const B2bAdminHamburgerMenuToggle = ({ onClick, ...buttonProps }: Props) => {
  const { isDesktop } = useActiveBreakpoint();
  const { t } = useTranslation(['messages']);

  return (
    <Button onClick={onClick} color={'tertiary'} data-cy="hamburger-menu-toggle" {...buttonProps}>
      {isDesktop ? (
        <>
          <UserSvg />
          <Text elementType={'span'} UNSAFE_className={'ml-400'}>
            {t('YOUR_ACCOUNT')}
          </Text>
        </>
      ) : (
        <Text elementType={'span'}>{t('MENU')}</Text>
      )}
    </Button>
  );
};

export default B2bAdminHamburgerMenuToggle;
